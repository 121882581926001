import { LANGUAGE } from 'config/i18n'
import { setLocaleCookie } from 'i18n/set-locale-cookie'
import { useLocale } from 'next-intl'
import React from 'react'

export const useLanguageSelect = ({
  changePathname,
}: {
  changePathname: boolean
}) => {
  const defaultLocale = useLocale()
  const [locale, setLocale] = React.useState(defaultLocale)

  const handleLocalesChange = async (locale: string) => {
    setLocale(locale)

    await setLocaleCookie(locale)

    if (changePathname) {
      location.pathname = location.pathname.startsWith('/' + defaultLocale)
        ? location.pathname.replace('/' + defaultLocale, '/' + locale)
        : '/' + locale + location.pathname
    } else {
      location.reload()
    }
  }

  const localeList = React.useMemo(() => {
    return LANGUAGE.map(child => ({
      name: child.key,
      title: child.name,
    })).sort((a, b) => {
      if (a.name === locale) {
        return -1
      }
      if (b.name === locale) {
        return 1
      }

      return 0
    })
  }, [locale])

  return {
    localeList,
    locale,
    handleLocalesChange,
  }
}

'use client'

import { cn } from '@paladise/ui/lib/utils'
import HLSPlayer from 'features/landing/components/HlsPlayer'
import { useLocale } from 'next-intl'
import React from 'react'
import { isIOS } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import {
  DESKTOP_VIDEO_POSTER,
  LOCALE_VIDEO_MAP,
  MOBILE_VIDEO_POSTER,
  VIDEOS,
} from '../constants'
import { HASH_KEY } from 'utils/hooks/useHash'

let muted = true
type Callback = () => void
const listeners = new Set<Callback>()
function subscribe(listener: Callback) {
  listeners.add(listener)
  return () => {
    listeners.delete(listener)
  }
}
function openSound() {
  muted = false
  listeners.forEach(listener => listener())
}
function toggleMuted() {
  muted = !muted
  listeners.forEach(listener => listener())
}

function snapshot() {
  return muted
}

export const MutedButton = () => {
  const isMuted = React.useSyncExternalStore(subscribe, snapshot, snapshot)

  return (
    <button
      aria-label="Toggle mute"
      onClick={toggleMuted}
      className="bg-overlay-light min768:p-[6px] flex cursor-pointer rounded-full p-[12px]"
    >
      <span
        aria-hidden="true"
        className={cn(
          'text-label-still-l1 [--icon-size:24px]',
          isMuted ? 'i-ps-volume_off_s' : 'i-ps-volume_on_s',
        )}
      />
    </button>
  )
}

const Video = () => {
  const locale = useLocale() as keyof typeof LOCALE_VIDEO_MAP
  const rendered = React.useSyncExternalStore(
    () => () => {},
    () => true,
    () => false,
  )
  const isSmallSize = useMediaQuery({ maxWidth: 767 })
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [opened, setOpened] = React.useState(false)
  const [touched, setTouched] = React.useState<'idle' | 'start' | 'end'>('idle')

  const VIDEO_MOBILE = VIDEOS[LOCALE_VIDEO_MAP[locale] || 'en'].MOBILE
  const VIDEO_DESKTOP = VIDEOS[LOCALE_VIDEO_MAP[locale] || 'en'].DESKTOP

  const touchstart = () => {
    openSound()
    setTouched('start')
  }
  const touchend = () => {
    requestAnimationFrame(() => {
      setTouched('end')
    })
  }

  const events = {
    onTouchStart: touched === 'idle' && isIOS ? touchstart : undefined,
    onTouchEnd: touchend,
    onClick: touched === 'end' || !isIOS ? toggleMuted : undefined,
  }

  React.useEffect(() => {
    if (!rendered) return
    const video = videoRef.current
    if (!video) return

    const onEnded = (event: any) => {
      if (
        event.currentTarget.currentTime >= event.currentTarget.duration - 1 &&
        !opened
      ) {
        window.location.hash = `#${HASH_KEY.DOWNLOAD_APP}`
        setOpened(true)
        video.removeEventListener('timeupdate', onEnded)
      }
    }

    video.addEventListener('timeupdate', onEnded)

    return () => {
      video.removeEventListener('timeupdate', onEnded)
    }
  }, [opened, rendered])

  React.useEffect(() => {
    if (!rendered) return
    const video = videoRef.current
    if (!video) return

    return subscribe(() => {
      if (!muted) {
        setTouched('end')
      }
      video.muted = muted
    })
  }, [isSmallSize, rendered])

  if (!rendered) return null

  if (isSmallSize) {
    return (
      <HLSPlayer
        className="absolute left-0 top-0 z-20 h-[calc(100%+2px)] w-full object-cover object-top"
        manifest={VIDEO_MOBILE.MANIFEST}
        mp4={VIDEO_MOBILE.MP4_MANIFEST}
        poster={MOBILE_VIDEO_POSTER}
        ref={videoRef}
        autoPlay
        playsInline
        loop
        muted
        {...events}
      />
    )
  }

  return (
    <HLSPlayer
      className="absolute left-0 top-0 z-20 h-[calc(100%+2px)] w-full object-cover object-top"
      manifest={VIDEO_DESKTOP.MANIFEST}
      mp4={VIDEO_DESKTOP.MP4_MANIFEST}
      poster={DESKTOP_VIDEO_POSTER}
      ref={videoRef}
      autoPlay
      playsInline
      loop
      muted
      {...events}
    />
  )
}

export default Video
